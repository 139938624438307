import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import enGb from "date-fns/locale/en-GB";
registerLocale("en-gb", enGb);

const CustomAnalytics = (props) => {
  const {
    analyticsStartDateSet,
    analyticsStartEndSet,
    analyticsStartEnd,
    analyticsStartDate,
  } = props;
  const [selectedDate, setSelectedDate] = useState(null);
  const datepickerRef = useRef(null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const handleDateSelect = (date) => {
    setSelectedDate(date);
  };

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen);
  };
  const isWeekend = (date) => {
    const day = dayjs(date).day();
    console.log("dayday", day);
    return day == 0 || day == 7; // Sunday (0) or Saturday (6)
  };

  const isInRange = (date) => {
    if (!selectedDate) {
      return false;
    }

    const startOfWeek = dayjs(selectedDate).startOf("week").day(0); // Start on Monday
    const startOfWeekGetDate = dayjs(selectedDate).startOf("week").day(1);
    const endOfWeek = dayjs(selectedDate).endOf("week").day(7);
    // analyticsStartDateSet(startOfWeekGetDate)
    // analyticsStartDateSet(analyticsStartEndSet)

    console.log("Start of Week:", startOfWeekGetDate.format("YYYY/MM/DD"));
    console.log("Start of Week:", endOfWeek.format("YYYY/MM/DD"));

    return dayjs(date).isAfter(startOfWeek) && dayjs(date).isBefore(endOfWeek);
  };

  useEffect(() => {
    if (selectedDate) {
      const startOfWeek = dayjs(selectedDate).startOf("week").day(1); // Start on Monday
      const endOfWeek = dayjs(selectedDate).endOf("week").day(7); // End on Sunday
      const formatStartDate = dayjs(startOfWeek).format("YYYY/MM/DD");
      const formatEndDate = dayjs(endOfWeek).format("YYYY/MM/DD");
      analyticsStartDateSet(formatStartDate);
      analyticsStartEndSet(formatEndDate);
      setIsDatePickerOpen(false);
    }
  }, [selectedDate]);

  const customDayClassName = (date) => {
    if (isInRange(date)) {
      return "selected-week";
    }
    if (isWeekend(date)) {
      return "weekend-day";
    }
    return null;
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        datepickerRef.current &&
        !datepickerRef.current.contains(event.target)
      ) {
        setIsDatePickerOpen(false);
      }
    };

    // Attach the event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="sign section--bg sign-one-bg week-date-range">
      <div className="bg-overlay">
        <div className="container">
          <div style={{ zIndex: "111" }}>
            <input
              type="text"
              readOnly
              className={`daterange float-right  mr-4  text-center `}
              value={
                analyticsStartDate === "ALL" && analyticsStartEnd === "ALL"
                  ? "Select Date Range"
                  : analyticsStartDate + " " + "-" + " " + analyticsStartEnd
              }
              onClick={toggleDatePicker} // Open datepicker on input click
              style={{
                width: "85%",
                fontWeight: 500,
                cursor: "pointer",
                background: "rgb(0 0 0 / 83%)",
                color: "#fff",
                display: "flex",
                justifyContent: "center",
                fontSize: "14px",
                padding: "10px",
                borderRadius: "6px",
                border: "1px solid var(--inputBorder--) !important",
                height: "48px !important",
              }}
            />
            {isDatePickerOpen && (
              <div ref={datepickerRef}>
                <DatePicker
                  locale="en-gb"
                  selected={selectedDate}
                  onChange={handleDateSelect}
                  inline
                  dayClassName={customDayClassName}
                  showPopperArrow={false}
                  maxDate={new Date()}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomAnalytics;
