import * as ActionTypes from "./Payouts.type";
const initialState = {
  payouts: [],
  commission: [],
  total: null,
};
export const payoutsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.GATE_AGENCY_PAYOUTS:
      return {
        ...state,
        payouts: action.payload.data,
        total: action.payload.total,
      };
    case ActionTypes.GET_COMMISSION:
      debugger
      return {
        ...state,
        commission: action.payload,
      };
    default:
      return state;
  }
};
